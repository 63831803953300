// extracted by mini-css-extract-plugin
export var arrow = "search-module--arrow--e1245";
export var backgroundImg = "search-module--backgroundImg--5da9c";
export var event = "search-module--event--03091";
export var eventTitle = "search-module--eventTitle--a5086";
export var eventTitleBox = "search-module--eventTitleBox--a2985";
export var infoWrapper = "search-module--infoWrapper--aa7e1";
export var mContainer = "search-module--mContainer--21844";
export var moreEvent = "search-module--moreEvent--b0673";
export var moreEventView = "search-module--moreEventView--4faf5";
export var pcContainer = "search-module--pcContainer--d0316";
export var question = "search-module--question--0629f";
export var section6 = "search-module--section6--f4379";
export var store = "search-module--store--c327a";
export var storeWrapper = "search-module--storeWrapper--be898";