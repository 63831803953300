import React, { useEffect, useState } from 'react';
import { getAnEvent } from '../../apis/eventApi';
import HeaderComp from '../../components/header';
import { Mobile, PC, useIsMobile } from '../../components/mediaQuery';
import WebLeftView from '../../components/searchComp/WebLeftView';

import * as styles from '../../styles/search/search.module.css';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';

export default function ProductDetail({ data }) {
  const [currentEvent, setCurrentEvent] = useState({} as any);
  const { appLink, appLinkApple, appLinkGoogle } = useSiteMetadata();
  const isMobile = useIsMobile();

  useEffect(() => {
    const init = async () => {
      const res = await getAnEvent();
      setCurrentEvent(res?.data?.[0]);
    };
    init();
  }, []);

  const handleDownClick = (eventName: string) => {
    return () => {
      let gotoLink = appLink;
      if (eventName.includes('app')) {
        // apple
        gotoLink = appLinkApple;
      } else if (eventName.includes('play')) {
        // google
        gotoLink = appLinkGoogle;
      }

      window.open(gotoLink);
    };
  };

  return (
    <div className={isMobile ? '' : styles.pcContainer}>
      <div className={isMobile ? '' : styles.backgroundImg} />
      <HeaderComp bold="search" />
      <div
        style={{
          height: isMobile ? 65 : 90,
        }}
      />
      {!isMobile && <WebLeftView currentEvent={currentEvent} handleDownClick={handleDownClick} />}
      <div>제품 상세</div>
    </div>
  );
}
