import axios from 'axios';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { amplitudeLog } from '../../Lib/ampletude';

import * as styles from '../../styles/search/search.module.css';

const WebLeftView = (props) => {
  const { currentEvent, handleDownClick } = props;

  return (
    <div
      style={{
        position: 'fixed',
        maxWidth: 512,

        left: 'calc(50vw - 512px)',
        marginTop: 100,
        height: 'fit-content',
      }}
    >
      <div className={styles.section6}>
        <div className={styles.infoWrapper}>
          <span>필터, 찜하기, 리뷰보기 등 더 편리한 사용을 원하신다면</span>
          <div style={{ margin: 'auto' }}>
            <h1>맘가이드 앱을 다운받아 보세요.</h1>
            <p>
              맘가이드 회원을 위한 <b>다양한 이벤트</b>도 준비되어 있어요 🎉
            </p>
          </div>
          {currentEvent?.thumbnail ? (
            <div
              style={{
                width: '70%',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Link to={`/events/${currentEvent.id}/`} className={styles.event}>
                <img
                  alt="이벤트 썸네일"
                  style={{
                    width: '100%',
                    // boxShadow: "0px 0px 5px #ffffff25",
                    boxShadow: '0px 0px 5px rgba(0,0,0, 0.25)',
                    objectFit: 'cover',
                  }}
                  src={currentEvent?.thumbnail}
                />
                {/* <div className={styles.eventTitleBox}>
                  <span className={styles.eventTitle}>{currentEvent.title}</span>
                </div> */}
              </Link>
              <div className={styles.moreEventView}>
                <Link
                  to={'/events/'}
                  className={styles.moreEvent}
                  onClick={() => {
                    amplitudeLog('진행 중인 이벤트 더보기', {
                      path: 'pc_search',
                    });
                  }}
                >
                  <span>진행 중인 이벤트 더보기</span>
                  <div className={styles.arrow} />
                </Link>
              </div>
            </div>
          ) : (
            <div style={{ minHeight: 230 }}></div>
          )}
        </div>
        <div className={styles.storeWrapper}>
          {/* <p className={styles.download}>맘가이드 어플 다운로드</p> */}
          <img
            alt="앱스토어 링크"
            onClick={handleDownClick('m_appstore')}
            src={'/images/main/button_appstore.png'}
            className={styles.store}
          />
          <img
            alt="플레이스토어 링크"
            onClick={handleDownClick('m_playstore')}
            src={'/images/main/button_googleplay.png'}
            className={styles.store}
          />
        </div>
      </div>
    </div>
  );
};

export default WebLeftView;
